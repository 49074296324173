<template>
  <!-- When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars -->
  <div>
    <header
      class="bg-[#0A0A0A] shadow-sm lg:static lg:overflow-y-visible">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <!-- Logo -->
          <div class="flex-shrink-0 group">
            <NuxtLink to="/"
              class="flex items-center gap-1 group-hover:text-white text-white/50 transition-colors duration-300">
              <svg class="w-8 h-auto" viewBox="0 0 300 300" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M218.543 123.052L300 257.552H137.086L218.543 123.052Z" fill="currentColor" />
                <path d="M69.5434 300H0L182.614 0L218.136 55.8029L69.5434 300Z" fill="currentColor" />
              </svg>
            </NuxtLink>
          </div>

          <!-- Search Box -->
          <div class="flex-1 px-4">
            <div class="w-full mx-auto">
              <label for="search" class="sr-only">Search</label>
              <div class="relative">

                <SearchBar />
              </div>
            </div>
          </div>
          <div class="inline-flex gap-2">
            <NuxtLink to="/account/me" class="my-auto">
              <UserCircleIcon class="h-6 w-6 text-white/50 hover:text-white transition-colors duration-300 ease-in-out my-auto"  v-if="isAuthenticated" />
            </NuxtLink>
            <MobileNavbarDropdown :isAuthenticated="isAuthenticated" @logout="emit('logout')" />
          </div>



        </div>
      </div>


    </header>
  </div>
</template>

<script setup>
import { Popover  } from '@headlessui/vue'
import {UserCircleIcon} from "@heroicons/vue/24/solid"
let emit = defineEmits(['logout'])
let props = defineProps({
  isAuthenticated: { type: Boolean, default: false }
})
</script>