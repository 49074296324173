<script setup>
import { ref, computed } from 'vue'
import { useDebounceFn, useActiveElement } from '@vueuse/core'
import { ComboboxAnchor, ComboboxContent, ComboboxEmpty, ComboboxGroup, ComboboxInput, ComboboxItem, ComboboxLabel, ComboboxRoot, ComboboxSeparator, ComboboxTrigger, ComboboxViewport } from 'radix-vue'
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/vue/24/solid"
import { UseWindowFocus } from '@vueuse/components'
import { useAudioPlayerStore } from '~/store'

const runtimeConfig = useRuntimeConfig();
console.log("[aphex] Runtime config:", runtimeConfig);

let audioPlayerStore = useAudioPlayerStore()
const searchQuery = ref('')
const isLoading = ref(false)
const valueSelected = ref(null)
const searchResults = ref({ genres: [], sets: [], artists: [], promoters: [], festivals: [] })
import { useFocusWithin } from '@vueuse/core'



const performSearch = async () => {
  if (!searchQuery.value) {
    searchResults.value = { genres: [], sets: [], artists: [], promoters: [], festivals: [] }
    return
  }

  isLoading.value = true
  console.log("[aphex] Performing search for:", searchQuery.value);

  try {
    const url = `${runtimeConfig.public.backend}/user/explore/search?query=${encodeURIComponent(searchQuery.value)}`;
    console.log("[aphex] Fetching from URL:", url);
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    searchResults.value = data
  } catch (error) {
    console.error("[aphex] Search error:", error);
    searchResults.value = { genres: [], sets: [], artists: [], promoters: [], festivals: [] }
  } finally {
    isLoading.value = false
  }
}

const debouncedSearch = useDebounceFn(performSearch, 300)

const hasResults = computed(() => {
  const result = Object.values(searchResults.value).some(arr => arr.length > 0);
  console.log("[aphex] hasResults:", result, "searchResults:", searchResults.value);
  return result;
})

const formatResult = (item) => {
  let result;
  switch (item.type) {
    case 'set':
      result = `${item.name} (${item.metadata.artists.join(', ')})`;
      break;
    case 'artist':
      result = `${item.name}${item.alias ? ` (${item.alias})` : ''}`;
      break;
    default:
      result = item.name;
  }
  console.log("[aphex] formatResult:", result);
  return result;
}

function createSlug(text) {
  if (!text) {
    return ''
  }
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove non-word chars (except spaces and hyphens)
    .replace(/[\s_-]+/g, '-') // Replace spaces, underscores, and hyphens with a single hyphen
    .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
}


const determineLink = (item) => {
  switch (item.type) {
    case 'set':
      return `/stream/${item.id}`;
  case 'artist':
      return `/search/artist/${item.slug}`;
    case 'festival':
      return `/search/festival/${createSlug(item.name)}`;
    case 'promoter':
      return `/search/promoter/${createSlug(item.name)}`;
    default:
      return `/search/genre/${item.id}`;
  }
}


watch(searchQuery, (newValue) => {
  console.log("[aphex] searchQuery changed to:", newValue);
  if (newValue) {
    debouncedSearch();
  } else {
    searchResults.value = { genres: [], sets: [], artists: [], promoters: [], festivals: [] };
  }
})


let inputFocus = ref(null)
const { focused } = useFocusWithin(inputFocus)

watch(searchResults, (newResults) => {
  console.log("[aphex] searchResults updated:", newResults);
})


/* Probably a bad idea but yeah */
watch(focused, (focused) => {
  if (focused) {
    console.log("[aphex] target is focusing on search input, blocking keyboard shortcuts")
    audioPlayerStore.ignoreKeyboardShortcuts = true
  } else {
    console.log("[aphex] target is NOT focusing on search input, unblocking keyboard shortcuts")
    if (audioPlayerStore.ignoreKeyboardShortcuts) {
      audioPlayerStore.ignoreKeyboardShortcuts = false
    }
  }
})




</script>

<template>
  <ComboboxRoot v-model:search-term="searchQuery" v-model:selected-value="valueSelected"
    class="w-full max-w-full sm:max-w-3xl md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto">
    <ComboboxAnchor class="w-full flex items-center bg-transparent text-white rounded-md shadow-sm group">
      <MagnifyingGlassIcon
        class="h-6 w-6 text-gray-400 ml-3 mr-4 group-hover:text-white transition-colors duration-300 ease-in-out hidden md:block" />
      <ComboboxInput ref="inputFocus"
        class="w-full pr-4 pl-4 py-2 bg-transparent outline-none text-white placeholder-white/50 border-b border-white/25 hover:border-white/50 focus:border-white transition-colors duration-300 ease-in-out"
        placeholder="Search..." />
      <ComboboxTrigger class="p-2 hidden md:block">
        <ChevronDownIcon class="h-5 w-5 text-gray-400" />
      </ComboboxTrigger>
    </ComboboxAnchor>
    <ComboboxContent
      class="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 sm:max-h-72 md:max-h-80 lg:max-h-96 overflow-auto">
      <ComboboxViewport class="py-1">
        <ComboboxEmpty v-if="!isLoading && !hasResults" class="px-4 py-2 text-sm text-gray-700">
          No results found
        </ComboboxEmpty>

        <div v-if="isLoading" class="px-4 py-2 text-sm text-gray-700">
          <Loading />
        </div>
        <template v-for="(results, category) in searchResults" :key="category">
          <ComboboxGroup v-if="results.length > 0">
            <ComboboxLabel class="px-4 py-2 text-xs font-semibold text-gray-500 uppercase tracking-wide">
              {{ category }}
            </ComboboxLabel>
            <ComboboxItem v-for="item in results" :key="item.id"
              class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" :value="item">
              <NuxtLink :to="determineLink(item)" class="flex items-center w-full">
                <span>{{ formatResult(item) }}</span>
              </NuxtLink>
            </ComboboxItem>
          </ComboboxGroup>
          <ComboboxSeparator v-if="results.length > 0" class="my-1 border-t border-gray-200" />
        </template>
      </ComboboxViewport>
    </ComboboxContent>
  </ComboboxRoot>
</template>