<script setup lang="ts">
import { Bars3Icon, UserIcon, UserPlusIcon, UserMinusIcon,ArrowUpTrayIcon } from "@heroicons/vue/24/solid"
import { ref } from 'vue'
let emit = defineEmits(['logout'])


const props = defineProps({
    isAuthenticated: {
        type: Boolean,
        default: false
    }
})

import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'radix-vue'

const toggleState = ref(false)

</script>

<template>
  <DropdownMenuRoot v-model:open="toggleState">
    <DropdownMenuTrigger
      class="rounded-full w-10 h-10 flex items-center justify-center text-white/50 hover:text-white transition-colors duration-300 focus:outline-none"
      aria-label="Menu options"
    >
      <Bars3Icon class="h-6 w-6" />
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
  
      <DropdownMenuContent
        class="bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 p-2 space-y-2 w-full sm:w-56 lg:mr-5 lg:mt-4"
      >
      
        <template v-if="isAuthenticated">
          <DropdownMenuItem class="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-200 rounded-md">
            <NuxtLink to="/account/upload" class="w-full">Upload</NuxtLink>
          </DropdownMenuItem>

          <DropdownMenuItem class="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-200 rounded-md">
            <NuxtLink to="/account/settings" class="w-full">Settings</NuxtLink>
          </DropdownMenuItem>

          <DropdownMenuSeparator class="my-2 border-t border-gray-200" />

          <DropdownMenuItem class="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-200 rounded-md cursor-pointer">
            <span @click="emit('logout')" class="w-full">
              Sign Out</span>
          </DropdownMenuItem>
        </template>

        <template v-else>
          <DropdownMenuItem class="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-200 rounded-md">
            <NuxtLink to="/account/login" class="w-full">Login</NuxtLink>
          </DropdownMenuItem>
          <DropdownMenuItem class="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-200 rounded-md">
            <NuxtLink to="/account/register" class="w-full">
              Register</NuxtLink>
          </DropdownMenuItem>
        </template>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>